  h1 {
    font-size: 2.5rem;
    margin-bottom: 24px;
    color: var(--text-color);
  }
  
  .last-updated {
    color: var(--placeholder-text);
    margin-bottom: 32px;
  }
  
  .section {
    padding: 24px;
    background-color: var(--card-bg);
    border-radius: 8px;
    margin-bottom: 24px;
    box-shadow: 0 2px 4px var(--card-shadow);
  }
  
  h2 {
    font-size: 1.5rem;
    margin-bottom: 16px;
    color: var(--text-color);
  }
  
  h3 {
    font-size: 1.2rem;
    margin-bottom: 12px;
    color: var(--text-color);
  }
  
  p {
    line-height: 1.6;
    margin-bottom: 16px;
    color: var(--text-color);
  }
  
  ul {
    margin-bottom: 24px;
    padding-left: 24px;
    color: var(--text-color);
    line-height: 1.6;
  }
  
  footer {
    margin-top: 48px;
    padding-top: 24px;
    border-top: 1px solid var(--card-bg);
    color: var(--placeholder-text);
    font-size: 0.9rem;
    text-align: center;
  }

@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  .section {
    padding: 16px;
  }

  h2 {
    font-size: 1.25rem;
  }

  h3 {
    font-size: 1rem;
  }

  p {
    margin-bottom: 12px;
  }

  ul {
    padding-left: 16px;
  }

  footer {
    font-size: 0.8rem;
  }
}