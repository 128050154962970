.input-wrapper {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 16px;
}
  
.tab-container {
    display: flex;
    gap: 4px;
    background-color: var(--card-bg);
    padding: 4px;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.tab-button {
    flex: 1;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 6px;
    border: none;
    background: none;
    cursor: pointer;
    transition: all 0.2s ease;
    color: var(--text-color);
}

.tab-button[data-text="description"]::before {
    content: "URL + Description 📝";
}

.tab-button.active {
    background-color: var(--button-bg);
    color: var(--button-text);
}

.tab-button:hover:not(.active) {
    background-color: var(--input-bg);
}

.input-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.input-container {
    position: relative;
    width: 100%;
}

.url-input {
    width: 100%;
    padding: 10px 40px 10px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: var(--input-bg);
    color: var(--text-color);
    box-sizing: border-box;
}

.url-input::placeholder {
    color: var(--placeholder-text);
    font-style: italic;
}

.textarea-container {
    display: flex;
    position: relative;
    width: 100%;
}

.textarea-input {
    width: 100%;
    min-height: 100px;
    padding: 10px 40px 10px 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    resize: vertical;
    font-family: inherit;
    line-height: 1.5;
    background-color: var(--input-bg);
    color: var(--text-color);
}

.textarea-input::placeholder {
    color: var(--placeholder-text);
    font-style: italic;
}

.url-clear-button,
.textarea-clear-button {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-color);
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.url-clear-button {
    top: 50%;
    transform: translateY(-50%);
}

.textarea-clear-button {
    top: 50%;
}

.url-clear-button:hover,
.textarea-clear-button:hover {
    opacity: 1;
}

.submit-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: var(--button-bg);
    color: var(--button-text);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #FFC72C;
}

.submit-button:disabled {
    background-color: #a5a6f6;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .input-form {
        gap: 12px;
    }

    .url-input,
    .textarea-input {
        font-size: 14px;
    }

    .textarea-input {
        min-height: 150px;
    }

    .submit-button {
        padding: 12px 16px;
    }

    .tab-button[data-text="description"]::before {
        content: "URL+Desc. 📝";
    }
}