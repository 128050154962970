.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--overlay-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-content {
  text-align: center;
  color: var(--text-color);
  z-index: 2;
  background: var(--card-bg);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px var(--card-shadow);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border: 1px solid var(--border-color);
}

.duck-container {
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 2s infinite ease-in-out;
}
  
@keyframes rotate {
  0% {
    transform: rotateZ(-1deg);
  }
  50% {
    transform: rotateZ(1deg);
  }
  100% {
    transform: rotateZ(-1deg);
  }
}

.duck-logo {
  width: 400px;
  height: auto;
}

.number {
  font-size: 16px;
  color: #FFD700;
  font-weight: bold;
}

.year-banner {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.year {
  background: linear-gradient(90deg, #FFD700, #FFA500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
}

.sparkle {
  animation: twinkle 1.5s ease-in-out infinite;
}

@keyframes twinkle {
  0%, 100% { opacity: 1; transform: scale(1); }
  50% { opacity: 0.5; transform: scale(0.8); }
}

.celebration-message {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  text-shadow: 0 2px 4px var(--card-shadow);
  color: var(--text-color);
}

.helper-message {
  font-size: 18px;
  opacity: 0.9;
  margin: 0;
  color: var(--text-color);
}