.header {
    justify-content: space-between;
}
.header-container {
    display: flex !important;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    position: relative;
    justify-content: space-between;
}

.App-logo {
    width: 75px;
    height: 75px;
    margin-right: 10px;
}

.logo-container {
    display: flex;
    align-items: center;
    position: relative; /* Ensure it doesn't overlap other elements */
    z-index: 0; /* Set a lower z-index to send it back */
}

.logo-container h1 {
    font-size: 32px;
    justify-content: center;
    margin: 0;
}

.signin-info-container {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    z-index: 1; /* Ensure it stays above other elements if necessary */
}

@media (max-width: 768px) {
    .header {
        justify-content: center;
    }

    .App-logo {
        margin-right: 5px;
        width: 50px;
        height: 50px;
    }

    .logo-container {
        display: flex; /* Ensure it uses flexbox */
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-right: 5px;
        justify-content: center; /* Center the logo-container */
        width: 100%; /* Ensure it takes full width */
        z-index: 0; /* Set a lower z-index to send it back */
    }

    .logo-container h1 {
        display: none;
    }
}