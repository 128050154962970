.headline-wrapper {
    margin: 1.5rem;
}
.headline {
    font-size: 40px;
    text-align: center;
    margin: 0px;
}
  
.subtitle {
    color: #6366f1;
    font-size: 40px;
    text-align: center;
    margin: 0px;
}
  
  @media (max-width: 768px) {
    .headline {
      font-size: 24px; /* Adjust as needed */
    }
    .subtitle {
      font-size: 24px;
    }
  }