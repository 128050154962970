.about-button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    background-color: transparent;
    color: var(--text-color); /* Add this line */
    border: 1.5px solid var(--button-border);
    box-shadow: 0 2px 4px var(--card-shadow);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.875rem;
    font-weight: 500;
}

.about-button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--overlay-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    z-index: 999;
}

.modal-content {
    background-color: var(--bg-color); /* Use background color from theme */
    color: var(--text-color); /* Use text color from theme */
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    border-bottom: 1px solid var(--border-color);
}

.info-title {
    color: var(--text-color); /* Use text color from theme */
    font-weight: 600;
    margin: 0;
}

.close-button {
    padding: 0.5rem;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    color: var(--text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.info-content {
    padding: 1rem 1.5rem;  /* Reduced top padding */
}

.info-section {
    padding: 16px;
    background-color: var(--card-bg);
    border-radius: 8px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px var(--card-shadow);
}

.info-section:first-child {
    margin-top: 0.5rem;  /* Add small top margin to first section */
}

.info-section-title {
    font-weight: 600;
    margin: 0 0 0.50rem 0;  /* Explicitly set all margins */
}

.info-text {
    color: var(--text-color); /* Use text color from theme */
    line-height: 1.5;
    margin: 0 0 0.5rem 0; /* Optional: Add some space between text and email */
    font-size: 1rem;
}

.info-step-container {
    display: flex;
    align-items: flex-start; /* Aligns items to the top */
    gap: 2 rem;
}

.info-step-container .text {
    margin-top: 0.25 rem; /* Space between title and description */
    margin-bottom: 0.25 rem; /* Remove bottom margin since container handles spacing */
}

.team-member {
    display: flex;
    align-items: flex-start; /* Aligns items to the top */
    gap: 1rem;
}

.team-title {
    font-weight: 600;
    margin: 0; /* Remove default margins */
    line-height: 1.5;
    font-size: 1rem;
}

.avatar {
    width: 96px;
    height: 96px;
    margin-right: 10px; /* Adds space between image and text */
    border-radius: 25%;
}

.info-footer {
  border-top: 1px solid #e2e8f0;
  padding-top: 12px;
}

.info-footer-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1.5rem;
    gap: 0.25rem; /* Adds consistent spacing between text and email */
}

.info-footer-text {
    color: var(--text-color); /* Use text color from theme */
    line-height: 1.5;
}

.link {
    color: var(--link); /* Use email link color from theme */
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
}

/* Hover states */
.info-button:hover {
    background-color: var(--hover-bg);
}

.close-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.link:hover {
    text-decoration: underline;
    color: var(--link-hover); /* Use email link hover color from theme */
}

.linkedin-link {
    display: inline-block;
    color: var(--linkedin-color);
    transition: transform 0.2s; /* Example transition */
}

.linkedin-link:hover {
    transform: scale(1.1); /* Example hover effect */
}

.linkedin-icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.info-contact-link {
    color: var(--link);
    text-decoration: none;
}

.info-contact-link::before {
    content: "<";
}

.info-contact-link::after {
    content: ">";
}

.info-contact-link:hover {
    text-decoration: underline;
    color: var(--link-hover);
}

/* Mobile styles */
@media (max-width: 768px) {
    .about-button {
        padding: 0.4rem 0.8rem; /* Adjust padding for smaller screens */
        font-size: 0.75rem; /* Smaller font size */
    }

    .modal-content {
        padding: 1rem; /* Reduce padding */
        max-width: 90%; /* Allow more width on mobile */
    }

    .info-header {
        padding: 0.25rem; /* Reduce header padding */
        align-items: center; /* Center items vertically */
        text-align: center; /* Center align the text */
    }

    .info-title {
        font-size: 1.125rem; /* Smaller title font size */
    }

    .info-content {
        padding: 0.25rem; /* Reduce content padding */
    }

    .info-section {
        margin-bottom: 0.5rem; /* Reduce margin */
        padding: 1rem 0.5rem; /* Reduce content padding */
    }

    .info-section-title {
        font-size: 1rem; /* Smaller title font size */
        margin: 0.25rem;
        margin-left: 0;
        align-items: center; /* Center items vertically */
        text-align: center; /* Center align the text */
    }

    .info-text {
        font-size: 0.875rem; /* Smaller text size */
        margin: 0 0 0.5rem 0; /* Optional: Add some space between text and email */
        align-items: center; /* Center items vertically */
        text-align: center; /* Center align the text */
    }

    .info-step-container {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items vertically */
        text-align: center; /* Center align the text */
    }
    
    .team-title {
        font-size: 1rem; /* Smaller section title font size */
        align-items: center; /* Center items vertically */
        text-align: center; /* Center align the text */
    }
    
    .avatar {
        width: 72px;
        height: 72px;
    }

    .info-footer {
        padding-top: 8px; /* Reduce footer padding */
    }

    .info-footer-content {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items */
        padding: 0.5rem; /* Reduce footer content padding */
    }

    .info-footer-text {
        font-size: 0.875rem; /* Smaller footer text size */
    }
    
    .team-member h4.info-title {
        text-align: left; /* Left align the text */
    }
    
    .team-member .info-text {
        text-align: left; /* Left align the text for the paragraph */
    }
    
    .info-contact-link {
        font-size: 0.875rem; /* Smaller footer text size */
    }
}