.results-container {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

.job-summary,
.resume-bullets {
    flex: 1;
    padding: 20px;
    border-radius: 4px;
    background-color: var(--card-bg);
}

.job-summary h2,
.resume-bullets h2 {
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 5px;
}

.job-summary p,
.resume-bullets p {
    font-weight: normal;
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 15px;
}

.placeholder-text {
    color: var(--placeholder-text);
    font-style: italic;
    text-align: left;
    margin-top: 20px;
    padding: 5px;
}

ul {
  padding-left: 20px;
  margin-top: 10px;
}

li {
  margin-bottom: 5px;
}

@media (max-width: 768px) {
    .results-container {
        flex-direction: column;
    }

    .job-summary h2,
    .resume-bullets h2 {
        font-size: 24px;
    }
}
  