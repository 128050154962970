.app-container {
    font-family: Arial, sans-serif;
    max-width: 1000px;
    margin: 0 auto;
    padding: 12px;
    display: flex;
    min-height: 90vh;
    flex-direction: column; /* Stack children vertically */
  }

  .main-content {
    transition: margin-left 0.3s ease-in-out;
    position: relative;
    min-height: 90vh;
  }