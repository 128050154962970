.feedback-message {
    margin: 10px 0;
    padding: 10px;
    border-left: 4px solid var(--feedback-border);
    border-radius: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: normal;
    overflow: hidden;
    max-height: none;
    gap: 4px;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    background-color: var(--feedback-bg);
    color: var(--feedback-text);
}

.error-message {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border-left: 4px solid var(--error-border);
    background-color: var(--error-bg);
    color: var(--error-text);
}

.inline-button {
    display: inline-block;
    padding: 3px 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    height: 100%;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    background-color: var(--button-bg);
    color: var(--button-text);
}

.inline-button:hover {
    background-color: #FFC72C;
}

.email-sender {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.email-input {
    padding: 3px;
    border-radius: 4px;
    white-space: nowrap;
    background-color: var(--input-bg);
    color: var(--text-color);
    border: 1px solid var(--text-color);
}