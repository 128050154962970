.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: var(--card-bg);
  color: var(--text-color);
  border-right: 1px solid var(--card-shadow);
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease-in-out;
  z-index: 1;
}

.sidebar.expanded {
  width: 256px;
}

.sidebar.collapsed {
  width: 64px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--card-shadow);
}

.navigation-title {
  margin-left: 8px;
  font-weight: 600;
}

.toggle-button {
  margin-left: auto;
  padding: 4px;
  border-radius: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
}

.toggle-button:hover {
  background-color: var(--input-bg);
}

.sidebar-content {
  flex: 1;
  overflow-y: auto;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.menu-item:hover {
  background-color: var(--input-bg);
}

.menu-icon {
  width: 20px;
  height: 20px;
}

.menu-text {
  margin-left: 8px;
}

.sidebar-footer {
  border-top: 1px solid var(--card-shadow);
  padding: 8px;
}

.sidebar.collapsed .menu-item {
  justify-content: center;
}

.record-container {
  display: flex;
  flex-direction: column;
}

.record-title {
  font-weight: 500;
  margin-bottom: 4px;
}

.record-company {
  font-size: 0.875rem;
  color: var(--text-muted);
}

.record-favorite {
  color: #eab308; /* yellow color for star */
  margin-top: 4px;
}

@media (max-width: 1024px) { /* Increased from 768px to 1024px */
  .sidebar.collapsed {
    width: 0px;
  }
}