.signin-container {
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
}

.signin-button {
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    background-color: transparent;
    color: var(--text-color); /* Add this line */
    border: 1.5px solid var(--button-border);
    box-shadow: 0 2px 4px var(--card-shadow);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.875rem;
    font-weight: 500;
}

.signin-button:hover {
    opacity: 0.9;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.profile-menu {
    position: relative;
}

.profile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 2px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    transition: border-color 0.2s ease;
    padding: 0;
    background: none;
}

.profile-button:hover {
    border-color: #6366f1;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-initial {
    width: 100%;
    height: 100%;
    background-color: var(--profile-initial-bg);
    color: var(--profile-initial-text);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6366f1;
    font-weight: 500;
}

.dropdown-menu {
    position: absolute;
    right: 0;
    top: calc(100% + 0.5rem);
    width: 12rem;
    background-color: var(--bg-color);
    box-shadow: 0 4px 6px -1px var(--dropdown-shadow);
    border: 1px solid var(--border-color);
    border-radius: 0.375rem;
    padding: 0.5rem;
    z-index: 50;
}

.dropdown-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: var(--dropdown-text);
    background: none;
    border: none;
    cursor: pointer;
    text-align: left;
}

.dropdown-item:hover {
    background-color: var(--hover-bg);
    border-radius: 0.375rem;
}

.dropdown-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

/* Hide sign in on mobile devices */
@media (max-width: 1024px) {  /* Increased from 768px to 1024px */
    .signin-container {
        display: none;
    }
}