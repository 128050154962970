:root {
    --bg-color: #ffffff;
    --text-color: #000000;
    --input-bg: #f0f0f0;
    --button-bg: #6366f1;
    --button-text: #ffffff;
    --card-bg: #f0f0f0;
    --card-shadow: rgba(0, 0, 0, 0.1);
    --feedback-bg: #e6f7ff;
    --feedback-border: #1890ff;
    --feedback-text: #0050b3;
    --error-bg: #ffebee;
    --error-border: #f44336;
    --error-text: #b71c1c;
    --link: #2563eb;
    --link-hover: #4a90e2;
    --placeholder-text: #888;
    --button-border: #6366f1;
    --overlay-bg: rgba(0, 0, 0, 0.8);
    --border-color: #e5e7eb;
    --hover-bg: #f3f4f6;
    --linkedin-color: #0077b5;
    --dropdown-shadow: rgba(0, 0, 0, 0.1);
    --profile-initial-bg: #f3f4f6;
    --profile-initial-text: #6366f1;
    --dropdown-text: #374151;
  }
  
  [data-theme="dark"] {
    --bg-color: #1a1a1a;
    --text-color: #ffffff;
    --input-bg: #2a2a2a;
    --button-bg: #6366f1;
    --button-text: #ffffff;
    --card-bg: #2c2c2c;
    --card-shadow: rgba(255, 255, 255, 0.1);
    --feedback-bg: #1a2733;
    --feedback-border: #0050b3;
    --feedback-text: #4d9fff;
    --error-bg: #2c1618;
    --error-border: #f44336;
    --error-text: #ff7373;
    --link: #2563eb;
    --link-hover: #4a90e2;
    --placeholder-text: #aaaaaa;
    --border-color: #2d2d2d;
    --hover-bg: #2a2a2a;
    --overlay-bg: rgba(0, 0, 0, 0.9);
    --profile-initial-bg: #2a2a2a;
    --profile-initial-text: #6366f1;
    --dropdown-text: #e5e7eb;
    --dropdown-shadow: rgba(255, 255, 255, 0.1);
  }