.settings-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.settings-content {
  background-color: var(--bg-color);
  color: var(--text-color);
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.settings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.settings-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: var(--text-color);
}

.settings-close-button {
  padding: 0.5rem;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.settings-close-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.settings-body {
  display: flex;
  height: 400px;
  overflow: hidden;
}

.settings-sidebar {
  width: 200px;
  border-right: 1px solid var(--border-color);
  padding: 0.5rem; /* Increased padding for space */
}

.settings-tab-button {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 0.375rem;
}

.settings-tab-button.active {
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 500;
}

.settings-main {
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
}

.settings-section {
  margin-bottom: 2rem;
}

.settings-group {
  background-color: var(--card-bg);
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid var(--border-color);
}

.settings-field {
  margin-bottom: 1rem;
}

.settings-field:last-child {
  margin-bottom: 0;
}

.settings-field label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: var(--text-color);
}

.settings-input,
.settings-select {
  width: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  color: var(--text-color);
  font-size: 0.875rem;
}

.settings-input:disabled {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: not-allowed;
}

/* Toggle switch styles */
.settings-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
}

.toggle-description {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.toggle-title {
  font-weight: 500;
  color: var(--text-color);
}

.toggle-subtitle {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  transition: opacity 0.1s;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  z-index: 2;
  /* Remove the transition from here */
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transform: translateX(0);
  z-index: 2;
  /* Remove the transition from here */
}

input:checked + .toggle-slider {
  background-color: #6366f1;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

input:checked + .theme-slider .sun {
  opacity: 0;
}

input:checked + .theme-slider .moon {
  opacity: 1;
}

.settings-footer {
  border-top: 1px solid var(--border-color);
  padding: 1rem 1.5rem;
}

.settings-action-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  background: none;
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.875rem;
}

.settings-action-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.theme-icon {
  font-size: 18px;
  transition: opacity 0.4s;
  z-index: 1;
}

.no-transition .toggle-slider,
.no-transition .toggle-slider:before,
.no-transition .theme-icon {
  transition: none !important;
}

.no-transition input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.no-transition input:checked + .toggle-slider {
  background-color: #6366f1;
}

/* Add a new class for transitions */
.enable-transitions .toggle-slider,
.enable-transitions .toggle-slider:before,
.enable-transitions .theme-icon {
  transition: all 0.4s ease;
}

/* Mobile styles */
@media (max-width: 640px) {
  .settings-content {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
  }

  .settings-body {
    flex-direction: column;
    height: auto;
  }

  .settings-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
  }

  .settings-tab-button {
    padding: 1rem;
  }

  .settings-main {
    padding: 1rem;
  }

  .settings-toggle {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
}